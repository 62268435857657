html,
body,
#root,
.App {
  height: 100%;
}

body {
  margin: 0;
  padding-top: 2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./img/background.png");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  white-space: pre-line;
  color: white;
  font-weight: 600;
  text-align: justify;
  font-family: "Open Sans", sans-serif;
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h2 {
  font-weight: 700;
  line-height: 85px;
  font-size: 72px;
  font-family: "Roboto", sans-serif !important;
  * {
    font-family: "Roboto", sans-serif !important;
  }
}

.button__primary {
  display: block;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  border-radius: 62px;
  width: 100%;
  padding: 1rem;
  text-align: center;
  background: linear-gradient(
    158deg,
    rgba(174, 86, 255, 1) 35%,
    rgba(100, 141, 253, 1) 100%
  );
  border: none;
  &:hover {
    box-shadow: 3px 3px 20px 1px rgba(133, 81, 221, 0.87);
  }
}

.button__secondary {
  text-align: center;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  border-radius: 62px;
  width: 100%;
  padding: 1rem;
  background-color: transparent;
  border: 1px solid #8551dd;
  &:hover {
    box-shadow: 3px 3px 20px 1px rgba(133, 81, 221, 0.87);
  }
}

a {
  text-decoration: none;
}

div#CybotCookiebotDialogHeader,
div#CybotCookiebotDialogPoweredByText,
.CybotCookiebotBannerCloseButton,
button#CybotCookiebotBannerCloseButtonE2E,
.CookiebotWidget-main-logo,
div#CookiebotWidget {
  display: none !important;
}

.CybotCookiebotDialogContentWrapper {
  * {
    font-family: "Open Sans", sans-serif !important;
  }
  button {
    text-align: center !important;
    display: block !important;
    font-family: "Open Sans", sans-serif !important;
    font-weight: 600 !important;
    border-radius: 62px !important;
    width: 100% !important;
    padding: 1rem !important;
    background-color: transparent !important;
    border: 1px solid #8551dd !important;
    color: #648dfd !important;
    &:hover {
      box-shadow: 3px 3px 20px 1px rgba(133, 81, 221, 0.87) !important;
    }
  }
}
